import '@src/assets/root.scss'
import '@src/utils/axios/config'

import * as React from 'react'
import { Suspense } from 'react'
import { Router } from 'react-router-dom'

import { useAppBundle } from '@src/areas/app/utils/hooks/useAppBundle'
import { useAppStorage } from '@src/areas/app/utils/hooks/useAppStorage'
import { useAuthContext } from '@src/areas/auth/utils/AuthContext'
import { AppLoader } from '@src/components/AppLoader'
import { history } from '@src/configureHistory'

const PublicScope = React.lazy(() => import('@src/areas/app/scopes/PublicScope'))
const PrivateScope = React.lazy(() => import('@src/areas/app/scopes/PrivateScope'))

const Root = () => {
    const { loggedIn } = useAuthContext()
    const bundleLoading = useAppBundle()
    useAppStorage()

    return (
        <Router history={history}>
            {!bundleLoading ? (
                <Suspense fallback={''}>{loggedIn ? <PublicScope /> : <PrivateScope />}</Suspense>
            ) : (
                <AppLoader />
            )}
        </Router>
    )
}

export default Root
