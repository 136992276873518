import Root from '@src/areas/app/Root'
import { AuthProvider } from '@src/areas/auth/utils/AuthContext'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

ReactDOM.render(
    <AuthProvider>
        <Root />
    </AuthProvider>,
    document.getElementById('root')
)
