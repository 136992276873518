import queryString from 'query-string'
import * as React from 'react'

import { AuthApiService, IAppInitialState, IRegistrationFormValues } from '@src/areas/auth/utils/api'
import { history } from '@src/configureHistory'
import { IApiResponse } from '@src/models/IApiResponse'

export interface IAuthContext {
    registrationValues: IRegistrationFormValues
    getRegistrationValues: () => Promise<any>
    registrationValuesLoading: boolean

    loggedIn: boolean
    signOut: (removeSessionStorageToken?: boolean) => Promise<void>
    signIn: (state: IAppInitialState) => void

    appInitialState: IAppInitialState
    authenticateInitially: () => Promise<IAppInitialState>
}

export const AuthContext = React.createContext<IAuthContext>(null)

export const useAuthContext = () => {
    return React.useContext(AuthContext)
}

export const AuthProvider: React.FC = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)

    const [registrationValues, setRegistrationValues] = React.useState<IRegistrationFormValues>(null)
    const [registrationValuesLoading, setRegistrationValuesLoading] = React.useState(true)
    const [appInitialState, setAppInitialState] = React.useState<IAppInitialState>(null)

    const getRegistrationValues = async () => {
        if (!registrationValues) {
            try {
                setRegistrationValuesLoading(true)
                const { data } = await AuthApiService.getRegistrationFormValues()
                setRegistrationValues(data)
                setRegistrationValuesLoading(false)
                return data
            } catch {}
        }

        return registrationValues
    }

    const signIn = (state: IAppInitialState) => {
        setAppInitialState(state)
        setIsLoggedIn(true)
    }

    const signOut = async (removeSessionstorageToken = true) => {
        try {
            await AuthApiService.signOut(removeSessionstorageToken)
            setAppInitialState(null)
            setIsLoggedIn(false)
            FreshworksWidget('destroy', 'launcher')
        } catch {}
    }

    const authenticateInitially = async () => {
        if (!appInitialState) {
            try {
                const queryToken = queryString.parse(history.location.search).token as string
                let response: IApiResponse<IAppInitialState>

                if (queryToken) {
                    response = await AuthApiService.pwdlessTokenSignIn(queryToken)
                } else {
                    response = await AuthApiService.tenantTokenSignIn(true)
                }

                if (response.successful) {
                    signIn(response.result)
                }
                return response.result
            } catch {
                setIsLoggedIn(false)
            }
        }

        return appInitialState
    }

    return (
        <AuthContext.Provider
            value={{
                loggedIn: isLoggedIn,
                signOut,
                signIn,
                getRegistrationValues,
                registrationValues,
                registrationValuesLoading,
                authenticateInitially,
                appInitialState
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
