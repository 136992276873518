import { authTokenName } from '@src/areas/auth/utils/api'
import { useAuthContext } from '@src/areas/auth/utils/AuthContext'
import { useEventListener } from '@src/hooks/useEventListener'

export const useAppStorage = () => {
    const { signOut, authenticateInitially } = useAuthContext()

    useEventListener('storage', (storage: StorageEvent) => {
        if (storage.key === authTokenName) {
            if (!storage.newValue) {
                signOut(false)
            }

            if (storage.newValue && !storage.oldValue) {
                authenticateInitially()
            }
        }
    })
}
