import i18n, { TFunction } from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// [ЁёА-я]
// for all options read: https://www.i18next.com/overview/configuration-options
// for essentials read: https://www.i18next.com/translation-function/essentials
// for using react way read: https://react.i18next.com/latest/usetranslation-hook
// for additional functions read: https://www.i18next.com/translation-function/formatting

export const initLocal = async () => {
    await i18n
        .use(languageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init(
            {
                fallbackLng: '',
                ns: [],
                defaultNS: 'common',
                fallbackNS: 'common',
                debug: false,
                backend: {
                    loadPath: `/locales/{{ns}}.{{lng}}.json`
                },
                interpolation: {
                    escapeValue: false // not needed for react as it escapes by default
                }
            },
            (error) => {
                if (!!error) {
                    console.log('i18n init -> ERROR!', error)
                    // todo: log error
                }
            }
        )
}

export enum NSKey {
    Common = 'common',
    Calendar = 'area.calendar',
    App = 'area.app',
    Billing = 'area.billing',
    Dict = 'area.dict',
    Export = 'area.export',
    OpReport = 'area.opreport',
    AnReport = 'area.anreport',
    Norms = 'area.norm',
    Tech = 'area.techdata',
    Order = 'area.order',
    Profile = 'area.profile',
    Settings = 'area.settings',
    Sms = 'area.sms',
    Search = 'area.search',
    Support = 'area.support',
    Spare = 'area.spare',
    Wh = 'area.warehouse',
    WhReport = 'area.whreport',
    PreOrder = 'area.preOrder',
    CashboxPayment = 'area.cashboxPayment',
    Telecom = 'area.telecom',
    Integration = 'area.integration',
    Auth = 'area.auth'
}

export const nsCreator =
    (nsKey: NSKey) =>
    (key: string): string => {
        return `${nsKey}:${key}`
    }

export interface IWithTranslation {
    t: TFunction
}

export default i18n
