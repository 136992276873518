import axios from 'axios'

import { IApplicationFeatures } from '@src/areas/app/models/IApplicationFeatures'
import { IApplicationSetting } from '@src/areas/app/models/IApplicationSetting'
import { IApplicationUser } from '@src/areas/app/models/IApplicationUser'
import { IUserTenant } from '@src/areas/app/models/IUserTenant'
import { IResetPasswordForm } from '@src/areas/auth/containers/ResetPassword'
import { ISignInForm } from '@src/areas/auth/containers/SignIn'
import { ISignUpForm } from '@src/areas/auth/containers/SignUp'
import { authParseTariff } from '@src/areas/auth/utils/parseTariff'
import { IChangePasswordForm } from '@src/components/Layout/Header/InfoBlock/components/Profile/components/ChangePasswordDialog/Form'
import { IApiResponse } from '@src/models/IApiResponse'

export interface IRegistrationFormValues {
    tariffOptions: ITenantRegistrationFormOption[]
    countryOptions: ITenantRegistrationFormOption[]
    languageOptions: ITenantRegistrationFormOption[]
    defaultLanguage: string
    defaultCountry: string
    defaultTariff: string
}

export interface ITenantRegistrationFormOption {
    title: string
    value: string
}

export interface IAppInitialState {
    webTitle: string
    appUserInitialState: IApplicationUser
    appSettingsInitialState: IApplicationSetting
    appFeaturesInitialState: IApplicationFeatures
    appAvailableTenants: IUserTenant[]
    appToken: string
    name: string
    email: string
    phone: string
    description: string
    enableJivoChat: boolean
}

export const authTokenName = 'appToken'
export const apiTokenName = 'sptoken'

const authStorageService = {
    getLocalStorageToken: () => localStorage.getItem(authTokenName),
    getCommonToken: () => {
        const sessionToken = sessionStorage.getItem(authTokenName)
        const localToken = authStorageService.getLocalStorageToken()

        return sessionToken && localToken ? sessionToken : localToken
    },
    removeLocalStorageToken: () => {
        localStorage.removeItem(authTokenName)
        sessionStorage.removeItem(authTokenName)
    },
    setLocalStorageToken: (token: string) => {
        localStorage.setItem(authTokenName, token)
        sessionStorage.setItem(authTokenName, token)
    }
}

export class AuthApiService {
    static getRegistrationFormValues = () => {
        return axios.get<IRegistrationFormValues>('/api/account/tenant-registration-form-values')
    }

    static register = (values: ISignUpForm) =>
        axios.post<IApiResponse<any>>('/api/account/register-tenant', {
            ...values,
            ...authParseTariff(values.tariff)
        })

    static signIn = (values: ISignInForm) => {
        return axios.post<IApiResponse<IAppInitialState>>('/api/account/user-sign-in', values).then(({ data }) => {
            if (data.successful) {
                const token = data.result.appToken

                if (values.remember) {
                    authStorageService.setLocalStorageToken(data.result.appToken)
                }

                axios.defaults.headers[apiTokenName] = token
            }

            return data
        })
    }
    static signOut = (removeSessionstorageToken: boolean) => {
        const appToken = axios.defaults.headers[apiTokenName]
        return axios
            .post<IApiResponse<any>>('/api/account/user-sign-out', { appToken })
            .then(({ data }) => {
                if (data.successful) {
                    localStorage.removeItem(authTokenName)
                    if (removeSessionstorageToken) {
                        sessionStorage.removeItem(authTokenName)
                    }
                }

                return data
            })
    }

    static tenantTokenSignIn = async (updateToken = false) => {
        const token = authStorageService.getCommonToken()

        if (token) {
            const { data } = await axios.post<IApiResponse<IAppInitialState>>('/api/account/tenant-token-sign-in', {
                token
            })

            if (data.successful) {
                const token = data.result.appToken

                if (updateToken) {
                    authStorageService.setLocalStorageToken(token)
                    axios.defaults.headers[apiTokenName] = token
                }

                return data
            } else {
                authStorageService.removeLocalStorageToken()
            }

            return data
        } else {
            throw Error()
        }
    }
    static pwdlessTokenSignIn = async (token: string) => {
        const { data } = await axios.post<IApiResponse<IAppInitialState>>('/api/account/pwdless-token-sign-in', {
            token
        })

        if (data.successful) {
            axios.defaults.headers[apiTokenName] = data.result.appToken
        }

        return data
    }
    static switchTenant = async (tenantToken: string) => {
        const { data } = await axios.post<IApiResponse<IAppInitialState>>(
            '/api/account/tenant-token-sign-in',
            {
                token: axios.defaults.headers[apiTokenName]
            },
            {
                params: {
                    tenant: tenantToken
                }
            }
        )

        if (data.successful) {
            const token = data.result.appToken
            axios.defaults.headers[apiTokenName] = token

            /* Если сохранили в localStorage, значит был флаг remember при логине */
            if (authStorageService.getLocalStorageToken()) {
                authStorageService.setLocalStorageToken(token)
            }

            return data
        } else {
            authStorageService.removeLocalStorageToken()
        }

        return data
    }

    static resetPassword = async (email: string) => {
        try {
            await axios.post<IApiResponse<any>>('/api/account/reset-password', {
                email
            })
        } catch {}
    }
    static resetPasswordConfirm = async ({ email, resetCode, newPassword }: IResetPasswordForm) => {
        try {
            return await axios.post<IApiResponse<any>>('/api/account/reset-password-confirm', {
                email,
                resetCode,
                newPassword
            })
        } catch {}
    }
    static changePassword = async (values: IChangePasswordForm) => {
        try {
            const { data } = await axios.post<IApiResponse<any>>('/api/account/change-password', {
                ...values,
                appToken: axios.defaults.headers[apiTokenName]
            })

            return data
        } catch {}
    }
}
