export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const ERROR = '_ERROR'

export const EMPTY_OBJECT = {}
export const LIST_PAGE_SIZE = 50

export const SHOW_PAYMENTS = true
// export const DEMO_TENANT = 'test_payments'
export const DEMO_TENANT = 'carservice'
export const PORTAL_INSTANCE_CARDONE = 'cardone'
export const PORTAL_INSTANCE_FAVICAR = 'favicar'

export const DEBUG_CHANGE_TOKEN = 'DEBUG_CHANGE_TOKEN'

export const OPTIONAL_NO = false
export const OPTIONAL_YES = true

export const DATE_DENOMINATION_DAY_BY = new Date(2016, 6, 1, 0, 0, 0, 0)

export const DATE_BEFORE_DENOMINATION_DAY_BY = new Date(2016, 5, 30, 0, 0, 0, 0)

export enum DOCUMENT_TYPES {
    NUMBERED = 'Numbered',
    UNNUMBERED = 'Unnumbered',
    TRAINING = 'Training'
}

export enum RESPONSIBLE_BY {
    WORKS = 'responsibleByWorks',
    ORDER = 'responsibleByOrder'
}

export const notFoundError = 'NotFound'

export const DATE_PATTERN_TEXT = 'дд.мм.гггг'
export const DATE_PATTERN = /^\d{1,2}\.\d{1,2}\.\d{4}$/
export const INTEGER_PATTERN = /^-?[0-9]+$/.toString()
export const FLOAT_PATTERN = /^-?[0-9]+([\.,]?[0-9]*)$/.toString()
export const PHONE_PATTERN = /^[0-9]+$/.toString()
export const DISCOUNT_PATTERN = /^-?[-?0-9]+([\.,]?[0-9]*)$/.toString()
export const PERCENT_PATTERN = /^100$|^\d{1,2}$/.toString()
export const FULL_PERCENT_PATTERN = /^-?[0-9] +$|^-?\d{1,2}$|^-?$/.toString()
export const FULL_SPARE_PERCENT_PATTERN = /^-?100$|^-?\d{1,2}$|^-?$/.toString()

/*TODO: Translate*/
export const PHONE_PLACEHOLDER = 'Пример: +XXX(XX)XXX-XX-XX'

export const COLOR_PALETTE = [
    '#76eca5',
    '#67c1ff',
    '#f577b2',
    '#de4223',
    '#d8bc4b',
    '#dae3f7',
    '#f9a040',
    '#cccccc',
    '#bcbbff',
    '#b5902a',
    '#5cbd17'
]

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]
export const DAYS_OF_WEEK = ['Понедельник', 'Вторник', 'Среда', 'Четверн', 'Пятница', 'Суббота', 'Воскресенье']

/*export const SPARE_MEASURES = [
    'шт.',
    'л',
    'пара',
    'комплект',
    'бут.',
    'мм',
    'см',
    'м',
    'мл',
    'г',
    'кг',
    'бут. 1л',
    'бут. 0,5л'
]*/

export const LOCALIZED_VALUES = {
    getSpareMeasures: (lang: string): string[] => {
        const values = {
            ru: [
                'шт.',
                'л',
                'пара',
                'комплект',
                'бут.',
                'мм',
                'см',
                'м',
                'м2',
                'мл',
                'г',
                'кг',
                'бут. 1л',
                'бут. 0,5л',
                'рул.',
                '100гр.',
                '100мл.',
                'уп.'
            ],
            en: [
                'pcs.',
                'l.',
                'pair',
                'set',
                'bottle',
                'mm',
                'cm',
                'm',
                'm2',
                'ml',
                'g',
                'kg',
                'bottle 1l',
                'bottle 0.5l'
            ],
            pl: [
                'szt.',
                'l.',
                'para',
                'kompl.',
                'but.',
                'mm',
                'cm',
                'm',
                'm2',
                'ml',
                'g',
                'kg',
                'but. 1l',
                'but. 0.5l'
            ],
            es: ['uds.', 'l.', 'par', 'set', 'bot.', 'mm', 'cm', 'm', 'm2', 'ml', 'g', 'kg', 'bot. 1l', 'bot. 0.5l'],
            de: ['stk.', 'l.', 'paar', 'set', 'fl.', 'mm', 'cm', 'm', 'm2', 'ml', 'g', 'kg', 'fl. 1l', 'fl. 0.5l'],
            uk: ['pcs.', 'л.', 'пара', 'комплект', 'пл.', 'мм', 'см', 'м', 'м2', 'мл', 'г', 'кг', 'пл. 1л', 'пл. 0.5л']
        }
        return values[lang] || values.ru
    }
}

export const MAX_NUMBER_COUNT_VALUE = 101
{
    /*
    Если min установлю на 0.0001, то на форме будет тяжело стираться значение
*/
}

export const MIN_NUMBER_SPARE_COUNT_VALUE = 0
export const MAX_NUMBER_SPARE_COUNT_VALUE = 99999
export const MAX_NUMBER_RATE_VALUE = 50001
export const MAX_NUMBER_NORMTIME_VALUE = 1001
export const MIN_NUMBER_PERCENT_VALUE = -100
export const MAX_NUMBER_PERCENT_VALUE = 1000001
export const MIN_NUMBER_FIELD_VALUE = -99999998
export const MAX_NUMBER_FIELD_VALUE = 99999999
