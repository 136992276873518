import { useEffect, useState } from 'react'

import { IAppInitialState } from '@src/areas/auth/utils/api'
import { useAuthContext } from '@src/areas/auth/utils/AuthContext'
import { initLocal } from '@src/i18n'

const loadPublic = async (data: IAppInitialState) => {
    await import('@src/areas/app/scopes/PublicScope')
    const fun = await import('@src/utils/launchers/configureApp')
    await fun.default(data)
}

/* Вся эта логика нужна, чтобы показывать слайдер, когда грузим бандл и после вызываем configureApp */
const loadApp = async (state: IAppInitialState) => {
    await initLocal()
    if (state) {
        await loadPublic(state)
    } else {
        await import('@src/areas/app/scopes/PrivateScope')
    }
}

export const useAppBundle = () => {
    const { loggedIn, authenticateInitially } = useAuthContext()

    const [isLoadingInitialState, setIsLoadingInitialState] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        setIsLoadingInitialState(true)

        authenticateInitially().then((state) => {
            setIsLoadingInitialState(false)

            if (!isLoadingInitialState) {
                loadApp(state).then(() => {
                    setLoading(false)
                })
            }
        })
    }, [loggedIn])

    return loading
}
