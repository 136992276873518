import { LocationDescriptorObject } from 'history'
import createHistory, { HashHistoryBuildOptions } from 'history/createHashHistory'

const defaultOptions: HashHistoryBuildOptions = {
    basename: '',
    hashType: 'slash' // i.e. #/home
}

export const history = createHistory(defaultOptions)

export const getLocationState = <T>(): T | undefined => {
    return history.location.state
}

interface ILocalLocationDescriptorObject<T> extends LocationDescriptorObject {
    state: T
}

export const historyPushWithState = <T>(state: ILocalLocationDescriptorObject<T>) => {
    history.push(state)
}
