enum TariffPlan {
    Basic = '1',
    Advanced = '2'
}

export const authParseTariff = (tariff: string) => {
    const [plan, months] = tariff.split('_')
    return {
        tariff: plan === 'basic' ? TariffPlan.Basic : TariffPlan.Advanced,
        months: Number(months)
    }
}
