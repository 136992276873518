function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1
}

const API_ROOT = endsWith(process.env.REACT_APP_API_ROOT, '/')
    ? process.env.REACT_APP_API_ROOT
    : process.env.REACT_APP_API_ROOT + '/'

export const environment = {
    isDisableCheckAppVersion: process.env.REACT_APP_CHECK_APP_VERSION?.toLowerCase() == 'false',
    isDisableTrackAppState: process.env.REACT_APP_TRACK_APP_STATE?.toLowerCase() == 'false',
    isDisableTrackUSerState: process.env.REACT_APP_TRACK_USER_STATE?.toLowerCase() == 'false',
    isDevEnvironment: process.env.NODE_ENV === 'development',
    API_ROOT
}
